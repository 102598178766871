import { toast } from 'react-toastify';
import { sendRequest } from 'config/api';

import {
  GET_COURSE,
  GET_COURSES,
  SEARCH_COURSES,
  SEARCH_COURSES_ALTERNATIVE,
  CATEGORY_COURSES,
  GET_ERRORS,
  GET_CATEGORIES,
  GET_MY_COURSES,
  GET_ASSINGED_COURSES,
  ADD_COURSE,
  GET_ACTIVE_COURSE_ID,
  ADD_COURSE_ERROR,
  UPDATE_COURSE_SETTINGS,
  GET_STUDENTS_ASSIGNED_TO_COURSES,
  SEARCH_STUDENTS_ASSIGNED_TO_COURSES,
  GET_COURSE_ASSIGNED_GROUPS,
  GET_COURSE_ASSIGNED_LOADING,
  SET_ADMIN_DASHBOARD_INFO,
  SET_STUDENT_INPROGRESS_COURSES,
  SET_STUDENT_ASSIGNED_COURSES,
  GET_COURSE_STUDENT,
  UPDATE_COMPLETED_COURSE_LESSONS,
  GET_MY_COMPLETED_COURSES,
  GET_MY_AVAILABLE_COURSES,
  GET_RESULTS,
  // EDIT_COURSE
  TOGGLE_COURSE_PUBLISH_STATUS,
  SET_STUDENT_CERTICATE_INFO
} from './types';
import { handleLoading } from 'actions/loadingAction';
import { formatErrors } from 'helpers/formatErrors';
// Get my courses -- STUDENT
export const getMyCourses = (categorylink, paginationink) => async (
  dispatch,
) => {
  try {
    const query = paginationink ? paginationink : categorylink;
    const { data } = await sendRequest(query || '/lms/course/my_courses/');
    dispatch({
      type: GET_MY_COURSES,
      payload: { data: data, searchstatus: false },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getStudentCourseSearch = (link) => async (dispatch) => {
  try {
    dispatch(handleLoading());
    const response = await sendRequest(link, null, 'GET');
    dispatch({
      type: GET_MY_COURSES,
      payload: { data: response.data, searchstatus: true },
    });

    dispatch(handleLoading());
  } catch (error) {
    dispatch(handleLoading());
    dispatch({
      type: GET_ERRORS,
      errorMessage: error.message,
    });
  }
};

export const getStudentCourseReset = (categorylink, paginationink) => async (
  dispatch,
) => {
  try {
    const query = paginationink ? paginationink : categorylink;
    const { data } = await sendRequest(query || '/lms/course/my_courses/');
    dispatch({
      type: GET_MY_COURSES,
      payload: { data: data, searchstatus: true },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getMyCompletedCourses = (categorylink, paginationink) => async (
  dispatch,
) => {
  try {
    const query = paginationink ? paginationink : categorylink;
    const { data } = await sendRequest(query);
    dispatch({
      type: GET_MY_COMPLETED_COURSES,
      payload: { data: data, searchstatus: false },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getMyCompletedCoursesSearch = (categorylink) => async (
  dispatch,
) => {
  try {
    const { data } = await sendRequest(categorylink, null, 'GET');
    dispatch({
      type: GET_MY_COMPLETED_COURSES,
      payload: { data: data, searchstatus: true },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getMyCompletedCoursesReset = (categorylink) => async (
  dispatch,
) => {
  try {
    const { data } = await sendRequest(categorylink, null, 'GET');
    dispatch({
      type: GET_MY_COMPLETED_COURSES,
      payload: { data: data, searchstatus: true },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getMyAvailableCourses = (categorylink, paginationink) => async (
  dispatch,
) => {
  try {
    const query = paginationink ? paginationink : categorylink;
    const { data } = await sendRequest(query);
    dispatch({
      type: GET_MY_AVAILABLE_COURSES,
      payload: { data: data, searchstatus: false },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getMyAvailableCoursesSearch = (categorylink) => async (
  dispatch,
) => {
  try {
    const { data } = await sendRequest(categorylink, null, 'GET');
    dispatch({
      type: GET_MY_AVAILABLE_COURSES,
      payload: { data: data, searchstatus: true },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getMyAvailableCoursesReset = () => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/lms/course/available/`, null, 'GET');
    dispatch({
      type: GET_MY_AVAILABLE_COURSES,
      payload: { data: data, searchstatus: true },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

/**
 * This method gets courses assigned to Admins or Instructors
 * @param {string} paginationink
 * @param {string} user_mode either instructor or null
 */

export const getAssignedCourses = (paginationink, user_mode) => async (
  dispatch,
) => {
  dispatch(handleLoading());
  try {
    const query = user_mode === 'ADMIN' ? '' : '?user_mode=INSTRUCTOR';

    const { data } = await sendRequest(
      paginationink ? paginationink : String('/lms/course/').concat(query),
    );

    dispatch({
      type: GET_ASSINGED_COURSES,
      payload: { data: data, searchstatus: false },
    });
    dispatch(handleLoading());
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getAssignedCoursesSearch = (paginationink) => async (dispatch) => {
  try {
    const { data } = await sendRequest(paginationink);

    dispatch({
      type: GET_ASSINGED_COURSES,
      payload: { data: data, searchstatus: true },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getAssignedCoursesReset = (paginationink, user_mode) => async (
  dispatch,
) => {
  dispatch(handleLoading());
  try {
    const query = user_mode === 'ADMIN' ? '' : '?user_mode=INSTRUCTOR';

    const { data } = await sendRequest(
      paginationink ? paginationink : String('/lms/course/').concat(query),
    );
    dispatch({
      type: GET_ASSINGED_COURSES,
      payload: { data: data, searchstatus: true },
    });
    dispatch(handleLoading());
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getCategoryCourses = (categoryLink, user) => async (dispatch) => {
  // dispatch(handleLoading());
  try {
    const { data } = await sendRequest(categoryLink);

    dispatch({
      type: CATEGORY_COURSES,
      payload: { data: data, user: user },
    });
    dispatch(handleLoading());
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

// Get all courses -- ADMIN
export const getCourses = (paginationink) => async (dispatch) => {
  try {
    const { data } = await sendRequest(paginationink || '/lms/course/');
    dispatch(handleLoading());
    dispatch({
      type: GET_COURSES,
      payload: { data: data, searchstatus: false },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getCoursesSearch = (paginationink) => async (dispatch) => {
  try {
    const { data } = await sendRequest(paginationink);

    dispatch({
      type: GET_COURSES,
      payload: { data: data, searchstatus: true },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getCoursesReset = (paginationink) => async (dispatch) => {
  try {
    const { data } = await sendRequest('/lms/course/');
    dispatch({
      type: GET_COURSES,
      payload: { data: data, searchstatus: true },
    });
    dispatch(handleLoading());
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const searchCourse = (query) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/lms/course/?search=${query}`);
    return data;
  } catch (error) {
    toast.error('Error searching courses');
  }
};

export const searchCourses = (searchLink, role) => async (dispatch) => {
  try {
    const { data } = await sendRequest(searchLink);

    dispatch({
      type: SEARCH_COURSES,
      payload: { data: data, user: role },
      // payload: data
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const searchCoursesAlternative = (searchTerm) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/lms/course/?user_mode=STUDENT&search=${searchTerm}`,
    );
    dispatch({
      type: SEARCH_COURSES_ALTERNATIVE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

// Get Category List
export const getCategories = () => async (dispatch) => {
  try {
    const { data } = await sendRequest('/lms/category/');
    dispatch({
      type: GET_CATEGORIES,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const addCourse = (course, toast) => async (dispatch) => {
  try {
    dispatch(handleLoading());
    const response = await sendRequest('/lms/course/', course, 'POST');
    dispatch({
      type: ADD_COURSE,
      payload: response.data,
    });
    toast.success('Course Created Sucessfully!');

    setTimeout(() => {
      window.location.reload();
    }, 3000);

    dispatch(handleLoading());
  } catch (error) {
    dispatch(handleLoading());
    dispatch({
      type: ADD_COURSE_ERROR,
      errorMessage: error.message,
    });
    toast.error(error.message);
  }
};

export const storeCourseId = (courseId) => (dispatch) => {
  dispatch({
    type: GET_ACTIVE_COURSE_ID,
    payload: courseId,
  });
};

export const getCourse = (courseId, userMode) => async (dispatch) => {
  try {
    dispatch(handleLoading());
    let url = userMode
      ? `/lms/course/${courseId}/?user_mode=${userMode}`
      : `/lms/course/${courseId}/`;

    const response = await sendRequest(url, null, 'GET');
    dispatch({
      type: GET_COURSE,
      payload: response.data,
    });
    dispatch(handleLoading());
  } catch (error) {
    dispatch(handleLoading());
    dispatch({
      type: GET_ERRORS,
      errorMessage: error.message,
    });
  }
};

/**
 *
 * @param {strng} courseId
 * The getStudentCourse action below gets a single course by id, but it calls a different endpoint from the getCourse action above. The endpoint returns extra details like completed lessons, and modules to enable progress report. After proper review both actions would most likely be merged into one. But to advoid breaking code... this way has been used.
 */
export const getStudentCourse = (courseId) => async (dispatch) => {
  try {
    dispatch(handleLoading());
    const response = await sendRequest(
      `/lms/course/${courseId}/my_course/`,
      null,
      'GET',
    );
    dispatch({
      type: GET_COURSE_STUDENT,
      payload: response.data,
    });
    dispatch(handleLoading());
  } catch (error) {
    dispatch(handleLoading());
    dispatch({
      type: GET_ERRORS,
      errorMessage: error.message,
    });
  }
};

export const updateCourseAfterMarking = (courseid, completedLessons) => async (
  dispatch,
) => {
  dispatch(getStudentCourse(courseid));
  dispatch({
    type: UPDATE_COMPLETED_COURSE_LESSONS,
    payload: completedLessons,
  });
};

export const updateCourseSettings = (
  { published, ...courseSettings },
  toast,
) => async (dispatch) => {
  try {
    const updateSettingsResponse = sendRequest(
      '/lms/course/settings/',
      courseSettings,
      'POST',
    );
    // const updateCoursePublish = sendRequest(
    //   `/lms/course/${courseSettings.course}/`,
    //   { published },
    //   'PATCH',
    // );
    // await Promise.all([updateSettingsResponse, updateCoursePublish]);
    await Promise.all([updateSettingsResponse]);
    dispatch({
      type: UPDATE_COURSE_SETTINGS,
      payload: {
        settings: courseSettings,
        published,
      },
    });
    toast.success('Settings Updated Successfully');
  } catch (error) {
    return formatErrors(error);
  }
};

/**
 * This action assigns one or more users to one or more courses
 * 
 * @param {{
    users: string[]; // an array of user id's
    courses: string[]; // an array of course id's
    user_mode: string; // an enum, either INSTRUCTOR or STUDENT
  }} assignData 
 * @param {*} toast 
 */
export const assignCoursesToUsers = (assignData, userMode, toast) => async (
  dispatch,
) => {
  try {
    await sendRequest('/lms/course/assign_course/', assignData, 'POST');
    if (userMode === 'Learning Groups') {
      toast.success(
        'The groups you selected have been successfully assigned to the course',
      );
    } else {
      toast.success(
        'The users you selected have been successfully assigned to the course',
      );
    }

    return;
  } catch ({ response, message }) {
    // toast.error(response.data || message);
    return response?.data?.message || message;
  }
};

export const assignCoursesToAllUsers = (assignData, userMode, toast) => async (
  disaptch,
) => {
  let parameter =
    userMode === 'Learning Groups' ? 'all_groups=true' : 'all_users=true';
  try {
    await sendRequest(
      '/lms/course/assign_course/?' + parameter,
      assignData,
      'POST',
    );
    if (userMode === 'Learning Groups') {
      toast.success(
        'The groups you selected have been successfully assigned to the course',
      );
    } else {
      toast.success(
        'The users you selected have been successfully assigned to the course',
      );
    }

    return;
  } catch ({ response, message }) {
    // toast.error(response.data || message);
    return response?.data?.message || message;
  }
};

export const UnassignCoursesToUsers = (assignData, toast) => async (
  dispatch,
) => {
  try {
    await sendRequest('/lms/course/unassign_course/', assignData, 'POST');

    toast.success(
      'The users you selected have been successfully removed from the course',
    );
    return;
  } catch ({ response, message }) {
    // toast.error(response.data || message);
    return response?.data?.message || message;
  }
};

export const UnassignCoursesToGroups = (groupid, assignData, toast) => async (
  dispatch,
) => {
  try {
    await sendRequest(`/auth/group/${groupid}/unassign/`, assignData, 'POST');

    toast.success(
      'The group you selected have been successfully removed from the course',
    );
    return;
  } catch ({ response, message }) {
    // toast.error(response.data || message);
    return response?.data?.message || message;
  }
};

export const searchStudents = (searchParam) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/auth/users/?search=${searchParam}&roles=STUDENT`,
      'GET',
    );

    if (data?.results?.length > 0) {
      const filteredData = data.results.map(
        ({ id, image, is_active, firstname, lastname }) => ({
          name: `${firstname} ${lastname}`,
          id,
          image,
          is_active,
        }),
      );

      return filteredData;
    }

    return [];
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const assignStudentsToCourse = ({ courses, ...rest }, toast) => async (
  dispatch,
) => {
  dispatch(handleLoading());
  try {
    await sendRequest(
      `/lms/course/assign_course/`,
      {
        courses,
        ...rest,
      },
      'POST',
    );
    getStudentsAssignedToCourse(null, courses[0])(dispatch);
    toast.success('Users Successfully assigned');
    dispatch(handleLoading());
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const getStudentsAssignedToCourse = (link, courseId, userMode) => async (
  dispatch,
) => {
  try {
    dispatch({ type: GET_COURSE_ASSIGNED_LOADING });
    const { data } = await sendRequest(
      link || `/lms/course/${courseId}/users/?user_mode=${userMode}`,
    );
    dispatch({
      type: GET_STUDENTS_ASSIGNED_TO_COURSES,
      payload: data,
    });
    dispatch({ type: GET_COURSE_ASSIGNED_LOADING });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
    dispatch({ type: GET_COURSE_ASSIGNED_LOADING });
  }
};

export const searchStudentAssignedToCourse = (searchTerm, courseId) => async (
  dispatch,
) => {
  try {
    const { data } = await sendRequest(
      `/lms/course/${courseId}/users/?search=${searchTerm}&user_mode=STUDENT`,
    );
    dispatch({
      type: SEARCH_STUDENTS_ASSIGNED_TO_COURSES,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const addGroupsToCourse = ({ courses, groups }, toast) => async (
  dispatch,
) => {
  try {
    const allResponse = groups.map((group) => {
      return sendRequest(`/auth/group/${group}/assign/`, { courses }, 'POST');
    });

    await Promise.all(allResponse);
    getCourseAssignedGroups(null, courses[0])(dispatch);
    toast.success('Groups Successfully assigned');
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const getCourseAssignedGroups = (link, courseId) => async (dispatch) => {
  try {
    dispatch({ type: GET_COURSE_ASSIGNED_LOADING });
    const { data } = await sendRequest(
      link || `/lms/course/${courseId}/groups/`,
    );
    dispatch({
      type: GET_COURSE_ASSIGNED_GROUPS,
      payload: data,
    });
    dispatch({ type: GET_COURSE_ASSIGNED_LOADING });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
    dispatch({ type: GET_COURSE_ASSIGNED_LOADING });
  }
};

export const getAdminDashboardInfo = () => async (dispatch) => {
  dispatch(handleLoading());
  try {
    const { data } = await sendRequest('/auth/users/dashboard/');
    dispatch({
      type: SET_ADMIN_DASHBOARD_INFO,
      payload: data,
    });
    dispatch(handleLoading());
  } catch (error) {
    console.log(error);
  }
};

export const getStudentAssignedCourses = () => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/lms/course/my_courses/`);
    dispatch({
      type: SET_STUDENT_ASSIGNED_COURSES,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const getStudentInProgressCourses = () => async (dispatch) => {
  dispatch(handleLoading());
  try {
    const { data } = await sendRequest(
      `/lms/course/my_courses/?in_progress=true`,
    );

    dispatch({
      type: SET_STUDENT_INPROGRESS_COURSES,
      payload: data.results,
    });
    dispatch(handleLoading());
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const editCourse = (course, courseId, toast, ifFromCourseCard) => async (
  dispatch,
) => {
  dispatch(handleLoading());

  try {
    await sendRequest(`/lms/course/${courseId}/`, course, 'PATCH');

    dispatch(handleLoading());
    toast.success('Course updated succesfully');
    dispatch(getCourse(courseId, ''));
    dispatch(getAssignedCourses(`/lms/course/?user_mode=INSTRUCTOR`));
    if (ifFromCourseCard) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    return;
  } catch ({ response, message }) {
    return response.data || message;
    // dispatch({
    //   type: GET_ERRORS,
    //   payload: error,
    // });
  }
};

export const deleteCourse = (courseid, ifFromCourseCard, toast) => async (
  dispatch,
) => {
  try {
    await sendRequest(`/lms/course/${courseid}/`, null, 'DELETE');

    dispatch(handleLoading());
    toast.success('Deleted successfully');
    dispatch(getCourse(courseid, 'INSTRUCTOR'));
    if (ifFromCourseCard) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }

    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};




export const requestCourseEnrollment = (requestData, toast) => async (
  dispatch,
) => {
  try {
    const { data } = await sendRequest(
      `/lms/course_requests/`,
      requestData,
      'POST',
    );
    dispatch({
      type: GET_RESULTS,
      payload: data,
    });
    dispatch(handleLoading());
    toast.success('Request sent');
    dispatch(getMyAvailableCoursesReset());
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const toggleCoursePublishedStatus = (courseId, published) => async (
  dispatch,
) => {
  try {
    await sendRequest(`/lms/course/${courseId}/`, { published }, 'PATCH');

    dispatch({
      type: TOGGLE_COURSE_PUBLISH_STATUS,
      payload: published,
    });
    toast.success(`Successfully ${published ? 'published' : 'Unpublised'}`);
    return;
  } catch ({ response, message }) {
    return toast.error(JSON.stringify(response?.data || message));
  }
};


//skip user payment
export const skipPayment = (toast, courseId, data) => async (dispatch) => {
  try {
    await sendRequest(`/lms/course/${courseId}/skip_payment/`, data, 'POST');
    getStudentsAssignedToCourse(null, courseId, 'STUDENT')(dispatch);
    getCourseAssignedGroups(null, courseId)(dispatch);
    toast.success('Payment Update Successful');
  } catch ({ response, message }) {
    toast.error('An Error Ocurred.');
  }
};



export const saveCertificate = (data) => async (dispatch) => {
  return await sendRequest(`/lms/certificate/`, data, 'POST');
 
};



export const getStudentCertificate = (user_id, course_id) => async (dispatch) => {
  dispatch(handleLoading());
  try {
    const { data } = await sendRequest(`/lms/certificate/${user_id}/${course_id}`);
    dispatch({
      type: SET_STUDENT_CERTICATE_INFO,
      payload: data,
    });
    dispatch(handleLoading());
  } catch (error) {
    console.log(error);
  }
};




