export const SIGNIN = 'SIGNIN';
export const SIGNUP = 'SIGNUP';
export const LOGOUT = 'LOGOUT';
export const VERIFY = 'VERIFY';
export const HANDLE_LOADING = 'HANDLE_LOADING';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const DOMAINS = 'DOMAINS';
export const GET_COURSES = 'GET_COURSES';
export const SEARCH_COURSES = 'SEARCH_COURSES';
export const SEARCH_COURSES_ALTERNATIVE = 'SEARCH_COURSES_ALTERNATIVE';
export const CATEGORY_COURSES = ' CATEGORY_COURSES ';
export const GET_COURSE = 'GET_COURSE';
export const GET_COURSE_STUDENT = 'GET_COURSE_STUDENT';
export const UPDATE_COMPLETED_COURSE_LESSONS =
  'UPDATE_COMPLETED_COURSE_LESSONS'; //updates a course after marking
export const GET_MY_COURSES = 'GET_MY_COURSES';
export const GET_ASSINGED_COURSES = 'GET_ASSINGED_COURSES';
export const ADD_COURSE = 'ADD_COURSE';
export const ADD_COURSE_ERROR = 'ADD_COURSE_ERROR';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_USERS = 'GET_USERS';
export const CREATE_LEARNING_GROUP_SUCCESS = 'CREATE_LEARNING_GROUP_SUCCESS';
export const CREATE_LEARNING_GROUP_FAILURE = 'CREATE_LEARNING_GROUP_FAILURE';
export const GET_ACTIVE_COURSE_ID = 'GET_ACTIVE_COURSE_ID';
export const SET_COMPANY = 'SET_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_DOMAIN = 'SET_DOMAIN';
export const SET_ACTIVE_ROLE = 'SET_ACTIVE_ROLE';
export const GET_COURSE_SETTINGS = 'GET_COURSE_SETTINGS';
export const UPDATE_COURSE_SETTINGS = 'UPDATE_COURSE_SETTINGS';
export const ASSIGN_STUDENTS_TO_COURSE = 'ASSIGN_STUDENTS_TO_COURSE';
export const GET_STUDENTS_ASSIGNED_TO_COURSES =
  'GET_STUDENTS_ASSIGNED_TO_COURSES';
export const SEARCH_STUDENTS_ASSIGNED_TO_COURSES =
  'SEARCH_STUDENTS_ASSIGNED_TO_COURSES';
export const GET_COURSE_ASSIGNED_GROUPS = 'GET_COURSE_ASSIGNED_GROUPS';
export const GET_COURSE_ASSIGNED_LOADING = 'GET_COURSE_ASSIGNED_LOADING';
export const SET_ADMIN_DASHBOARD_INFO = 'SET_ADMIN_DASHBOARD_INFO';
export const SET_STUDENT_ASSIGNED_COURSES =
  'STUDENT/SET_STUDENT_ASSIGNED_COURSES';
export const SET_STUDENT_INPROGRESS_COURSES =
  'STUDENT/SET_STUDENT_INPROGRESS_COURSES';
export const GET_ERRORS_FOR_STARTED_QUESTIONS =
  ' GET_ERRORS_FOR_STARTED_QUESTIONS';
export const EDIT_COURSE = 'EDIT_COURSE';
export const TOGGLE_COURSE_PUBLISH_STATUS = 'TOGGLE_COURSE_PUBLISH_STATUS';

export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW';
export const GET_MY_COMPLETED_COURSES = 'GET_MY_COMPLETED_COURSES';
export const GET_MY_AVAILABLE_COURSES = 'GET_MY_AVAILABLE_COURSES';
export const SET_LOADER = 'SET_LOADER';
export const GET_CURRENT_PROFILE = ' GET_CURRENT_PROFILE';
export const GET_MY_PENDING_COURSES = 'GET_MY_PENDING_COURSES';
export const GET_MY_ACCEPTED_COURSES = 'GET_MY_ACCEPTED_COURSES';
export const GET_MY_REJECTED_COURSES = 'GET_MY_REJECTED_COURSES';
export const GET_PENDING_COURSES = 'GET_PENDING_COURSES';
export const GET_ACCEPTED_COURSES = 'GET_ACCEPTED_COURSES';
export const GET_REJECTED_COURSES = 'GET_REJECTED_COURSES';
export const GET_RESULTS = 'GET_RESULTS';
export const RESET_PROGRESS = 'RESET_PROGRESS';
export const SET_STUDENT_CERTICATE_INFO = 'SET_STUDEN_CERTICATE_INFO';

// export const SET_DOMAIN = 'SET_DOMAIN';
export * from './settingsTypes';
