import { lazy } from 'react';

const Signup = lazy(() => import('./pages/Auth/Signup'));
const ForgortPassword = lazy(() => import('./pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'));
const VerifySuccess = lazy(() => import('./pages/Auth/Signup/Success'));
const CertificatePage = lazy(() => import('./pages/CourseDetails/CertificatePage'));

const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const Courses = lazy(() => import('./pages/Courses'));
const Store = lazy(() => import('./pages/CourseStore'));
const Signin = lazy(() => import('./pages/Auth/Signin'));
const VerifyAccount = lazy(() => import('./pages/Auth/Signup/Verify'));

const CreateCourse = lazy(() => import('pages/CreateCourse/CreateCourse'));
const ManageCourse = lazy(() => import('pages/ManageCourse'));
const ManageCourse2 = lazy(() => import('pages/ManageCourse/index2'));
const InstructorsProjectView = lazy(() =>
  import('pages/InstructorProjectView'),
);

const CourseEnrollment = lazy(() => import('pages/CourseEnrollment'));
const Users = lazy(() => import('pages/Users'));
const LearningGroup = lazy(() => import('pages/LearningGroup'));
const CourseDetails = lazy(() => import('pages/CourseDetails'));
const Settings = lazy(() => import('pages/Settings'));
const NotFound = lazy(() => import('pages/NotFound'));

const Forum = lazy(() => import('pages/Forum'));
const Topics = lazy(() => import('pages/Topics'));
const TopicView = lazy(() => import('pages/TopicView'));
const Messages = lazy(() => import('pages/Messages'));
const Reports = lazy(() => import('pages/Reports'));
const UserReports = lazy(() => import('pages/UserReports'));
const LearningGroupReports = lazy(() => import('pages/LearningGroupReports'));
const CourseReports = lazy(() => import('pages/CourseReports'));
const LeaderBoard = lazy(() => import('pages/LeaderBoard'));
const Files = lazy(() => import('pages/Files'));
const Profile = lazy(() => import('pages/Profile'));
const Events = lazy(() => import('pages/Events'));
const ExpiredSubscription = lazy(() => import('pages/ExpiredSubscription'));
const UnsupportedBrowser = lazy(() =>
  import('components/UnsupportedBrowsersWarning'),
);

const BuyCourses = lazy(() => import('pages/Courses/Buy'));

export const privateRoutes = [
  {
    url: '/',
    component: Dashboard,
    exact: true,
  },
  {
    url: '/courses/buy/:courseId',
    component: BuyCourses,
  },
  {
    url: '/courses/',
    component: Courses,
  },

  {
    url: '/store',
    component: Store,
  },

  {
    url: '/users',
    component: Users,
  },
  {
    url: '/events/:toEvent',
    component: Events,
  },
  {
    url: '/events',
    component: Events,
  },

  {
    url: '/create-course/',
    component: CreateCourse,
  },
  {
    url: '/manage-course/:courseId/',
    component: ManageCourse2,
  },
  {
    url: '/manage-courses/:courseId/',
    component: ManageCourse,
  },
  {
    url: '/learning-group/:groupId',
    component: LearningGroup,
  },
  {
    url: '/course-details/:courseId/',
    component: CourseDetails,
  },
  {
    url: '/settings',
    component: Settings,
  },
  { url: '/forum', component: Forum },
  {
    url: '/topics/:forumId',
    component: Topics,
  },
  {
    url: '/topic/:topicId',
    component: TopicView,
  },
  {
    url: '/messages/conversations/:userId',
    component: Messages,
  },
  {
    url: '/messages',
    component: Messages,
  },
  {
    url: '/reports/course/:courseId',
    component: CourseReports,
  },
  {
    url: '/reports/learning-group/:learningGroupId',
    component: LearningGroupReports,
  },
  { url: '/reports/user/:userId', component: UserReports },
  {
    url: '/reports',
    component: Reports,
  },

  {
    url: '/leaderboard',
    component: LeaderBoard,
  },
  {
    url: '/files',
    component: Files,
  },
  {
    url: '/profile/:userId',
    component: Profile,
  },
  {
    url: '/instruct-projects/:projectId/course/:courseId',
    component: InstructorsProjectView,
  },
  {
    url: '/course-enrollment/',
    component: CourseEnrollment,
  },
];

export const publicRoutes = [
  {
    url: '/signup',
    component: Signup,
  },
  {
    url: '/signin',
    component: Signin,
  },
  {
    url: '/forgotpassword/',
    component: ForgortPassword,
  },
  {
    url: '/password/reset/:token',
    component: ResetPassword,
  },
  {
    url: '/verify/:token',
    component: VerifyAccount,
  },
  {
    url: '/success',
    component: VerifySuccess,
  },

  {
    url: '/not-found',
    component: NotFound,
  },
  {
    url: '/expired-subscription',
    component: ExpiredSubscription,
  },
  {
    url: '/unsupported-browser',
    component: UnsupportedBrowser,
  },
  {
    url: '/view-course-certificate/:userId/:courseId',
    component: CertificatePage,
  },
];
